import { withTranslation, useTranslation } from "react-i18next";

import ProductImage from "../productImage/productImage";
import { displayGuarantee } from "./utils";
import { getTranslation } from "../specifications/utils";

import MasterLogo from "../../assets/images/masterLogo.png";
import MasterLogoLight from "../../assets/images/masterLogoLight.jpg";

import WatchidLogo from "../../assets/images/watchidLogo.png";
import WatchidLogoLight from "../../assets/images/watchidLogoLight.png";
import BasicLogo from "../../assets/images/basicLogo.svg";
import BasicLogoLight from "../../assets/images/basicLogoLight.png";
import Confederation from "../../assets/images/confederation.png";
import ConfederationLight from "../../assets/images/confederationLight.png";

import "./productDetails.scss";

const ProductDetails = ({
  watchData,
  businessData,
  lightTheme,
  displayMetas,
}) => {
  const { t } = useTranslation();
  const isEs = localStorage.getItem("i18nextLng").match(/^(es)$/) && true;
  const masterCert = t("masterCert").split('#');
  const masterCertEs = isEs ? t("masterCert").split('I') : [];

  return (
    <div
      className={`productDetails ${lightTheme ? "productDetailsLight" : ""}`}
    >
      <div className="imagesWrapper">
        <img
          className={lightTheme ? "watchidLogoLight" : "watchidLogo"}
          alt=""
          src={lightTheme ? WatchidLogoLight : WatchidLogo}
        ></img>
        <img
          className="basicLogo"
          alt={t("img.basicLogo")}
          src={lightTheme ? BasicLogoLight : BasicLogo}
        ></img>
        <ProductImage
          rmc={
            watchData?.Configuration?.Rmc
              ? watchData?.Configuration?.Rmc
              : watchData?.Configuration?.Model
          }
        />
      </div>
      <div className="detailsWrapper">
        <div className="smallTitle">
          {getTranslation(businessData, "TDR_MODEL_NAME")}
        </div>
        <div className="currentText">
          {watchData?.Configuration?.Model ? (
            <>
              {t("reference")} {watchData?.Configuration?.Model.substring(1)}
            </>
          ) : null}
        </div>
        <div className="currentText">
          {t("serialN")} {watchData?.SerialNumber}
        </div>

        {displayGuarantee(
          watchData?.WarrantyEndDate,
          t("underGuarantee"),
          t("serviceGuarantee"),
          t("monthYearFormat")
        )}

        {displayMetas && (
          <>
            <div className="xsTitle">
              {
                masterCertEs && masterCertEs.length ?
                  <>
                    {masterCertEs[0]}
                    <i>
                      {masterCertEs[1]}
                    </i>
                  </>
                  : masterCert && masterCert.length ?
                    <>
                      <span>
                        {masterCert[0]}
                      </span>
                      <span>
                        {masterCert[1]}
                      </span>
                    </>
                    : t("masterCert")
              }
            </div>
            <img
              className="masterLogo"
              alt=""
              src={lightTheme ? MasterLogoLight : MasterLogo}
            ></img>
          </>
        )}
      </div>

      {displayMetas && (
        <img
          className="confederation"
          src={lightTheme ? ConfederationLight : Confederation}
          alt=""
        />
      )}
    </div>
  );
};

export default withTranslation()(ProductDetails);

import { withTranslation, useTranslation } from "react-i18next";
import Placeholder from "../../assets/images/placeholder.jpg";
import "./productImage.scss";

const ProductImage = ({ rmc }) => {
  const rmcModified = rmc?.replace("/", "_");
  const productImgUrl = `https://asset.myrolexnetwork.com/catalogues/pedigree/tudor/${rmcModified?.toLowerCase()}_pedigree.png`;
  const { t } = useTranslation();

  return (
    <img
      alt={t("img.productImage")}
      className="productImage"
      src={productImgUrl}
      onError={(e) => {
        e.target.onError = Placeholder;
        e.target.src = Placeholder;
      }}
    />
  );
};

export default withTranslation()(ProductImage);
